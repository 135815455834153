import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {ClientDownloadsComponent} from './client-downloads/client-downloads.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {EmptyHeaderComponent} from './containers/empty-header/empty-header.component';
import {UserInfoComponent} from './user-info/user-info.component';
import {SecurityComponent} from './security/security.component';
import {LogoutService} from './services/logout.service';
import {JwtHelperService} from './services/jwt-helper.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    ClientDownloadsComponent,
    MatButtonModule,
    EmptyHeaderComponent,
    UserInfoComponent,
    EmptyHeaderComponent,
    TranslateModule,
    SecurityComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  userProfile?: KeycloakProfile;

  constructor(
    private readonly keycloak: KeycloakService,
    private translateService: TranslateService,
    private logoutService: LogoutService,
    private jwtHelperService: JwtHelperService,
    private keycloakService: KeycloakService
  ) {
    const language = navigator.language.split('-')[0];
    if (language === 'de' || language === 'en') {
      this.translateService.setDefaultLang(language);
    } else {
      this.translateService.setDefaultLang('en');
    }

    if (this.keycloakService.isLoggedIn()) {
      // If user is signed into keycloak, but the token cannot be parsed navigate to 'login'-page
      jwtHelperService
        .getUserDataFromToken()
        .then(async _ => {
          this.userProfile = await this.keycloak.loadUserProfile();
        })
        .catch(_ => {
          this.logoutService.logout();
        });
    }

    this.keycloakService.keycloakEvents$.subscribe(event => {
      if (event.type === KeycloakEventType.OnAuthRefreshError) {
        this.logoutService.logout();
      }
    });
  }

  ngOnInit(): void {
    this.logoutService.enableAutoLogout().subscribe();
  }
}

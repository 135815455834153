import {Injectable} from '@angular/core';
import {jwtDecode} from 'jwt-decode';
import {UserData} from '../dtos/lancrypt/user-data';
import {KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class JwtHelperService {
  private userData: UserData | null = null;

  constructor(private keycloak: KeycloakService) {}

  public async getTenantIdFromToken(): Promise<string> {
    const token = (await this.keycloak.getToken()) as any;
    return this.decodeToken(token).tenantIds[0];
  }

  public async getUserDataFromToken(): Promise<UserData> {
    if (this.userData !== null) {
      return Promise.resolve(this.userData);
    }
    const userData = await this.parseUserData();
    this.userData = userData;
    return userData;
  }

  public async getToken() {
    const token = (await this.keycloak.getToken()) as any;
    return this.decodeToken(token);
  }

  private async parseUserData(): Promise<UserData> {
    return this.keycloak.getToken().then(token => {
      const decodedToken = this.decodeToken(token) as any;

      return {
        id: '',
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        email: decodedToken.email,
        tenantId: decodedToken.tenantIds[0],
        languageInfo: '',
        regionalFormats: '',
      };
    });
  }

  private decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch {
      return null;
    }
  }
}
